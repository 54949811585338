export const results = [
    {
        id: 1,
        Heading: "Financial Results FY 2024",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter24-4.pdf",
        isActive: 1
    },
    {
        id: 2,
        Heading: "Financial Results FY 2024",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter24-3.pdf",
        isActive: 1
    },
    {
        id: 3,
        Heading: "Financial Results FY 2024",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter24-2.pdf",
        isActive: 1
    },
    {
        id: 4,
        Heading: "Financial Results FY 2024",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter24-1.pdf",
        isActive: 1
    },
    {
        id: 5,
        Heading: "Financial Results FY 2023",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter23-4.pdf",
        isActive: 1
    },
    {
        id: 6,
        Heading: "Financial Results FY 2023",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter23-3.pdf",
        isActive: 1
    },
    {
        id: 7,
        Heading: "Financial Results FY 2023",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter23-2.pdf",
        isActive: 1
    },
    {
        id: 8,
        Heading: "Financial Results FY 2023",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter23-1.pdf",
        isActive: 1
    },
    {
        id: 9,
        Heading: "Financial Results FY 2022",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter22-4.pdf",
        isActive: 1
    },
    {
        id: 10,
        Heading: "Financial Results FY 2022",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter22-3.pdf",
        isActive: 1
    },
    {
        id: 11,
        Heading: "Financial Results FY 2022",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter22-2.pdf",
        isActive: 1
    },
    {
        id: 12,
        Heading: "Financial Results FY 2022",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter22-1.pdf",
        isActive: 1
    },
    {
        id: 13,
        Heading: "Financial Results FY 2021",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter21-4.pdf",
        isActive: 1
    },
    {
        id: 14,
        Heading: "Financial Results FY 2021",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter21-3.pdf",
        isActive: 1
    },
    {
        id: 15,
        Heading: "Financial Results FY 2021",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter21-2.pdf",
        isActive: 1
    },
    {
        id: 16,
        Heading: "Financial Results FY 2021",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter21-1.pdf",
        isActive: 1
    },
    {
        id: 17,
        Heading: "Financial Results FY 2020",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter20-4.pdf",
        isActive: 1
    },
    {
        id: 18,
        Heading: "Financial Results FY 2020",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter20-3.pdf",
        isActive: 1
    },
    {
        id: 19,
        Heading: "Financial Results FY 2020",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter20-2.pdf",
        isActive: 1
    },
    {
        id: 20,
        Heading: "Financial Results FY 2020",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter20-1.pdf",
        isActive: 1
    },
    {
        id: 21,
        Heading: "Financial Results FY 2019",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter19-4.pdf",
        isActive: 1
    },
    {
        id: 22,
        Heading: "Financial Results FY 2019",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter19-3.pdf",
        isActive: 1
    },
    {
        id: 23,
        Heading: "Financial Results FY 2019",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter19-2.pdf",
        isActive: 1
    },
    {
        id: 24,
        Heading: "Financial Results FY 2019",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter19-1.pdf",
        isActive: 1
    },
    {
        id: 25,
        Heading: "Financial Results FY 2018",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://www.educomp.com/Data/Quarter18-4.pdf",
        isActive: 1
    },
    {
        id: 26,
        Heading: "Financial Results FY 2018",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://www.educomp.com/Data/Quarter18-3.pdf",
        isActive: 1
    },
    {
        id: 27,
        Heading: "Financial Results FY 2018",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Quarter18-2.pdf",
        isActive: 1
    },
    {
        id: 28,
        Heading: "Financial Results FY 2018",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Quarter18-1.pdf",
        isActive: 1
    },
    {
        id: 29,
        Heading: "Financial Results FY 2017",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://www.educomp.com/Data/Q1QR_17.pdf",
        isActive: 1
    },
    {
        id: 30,
        Heading: "Financial Results FY 2017",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://www.educomp.com/Data/Q2QR_17.pdf",
        isActive: 1
    },
    {
        id: 31,
        Heading: "Financial Results FY 2017",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_17.pdf",
        isActive: 1
    },
    {
        id: 32,
        Heading: "Financial Results FY 2016",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_16.pdf",
        isActive: 1
    },
    {
        id: 33,
        Heading: "Financial Results FY 2016",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_16.pdf",
        isActive: 1
    },
    {
        id: 34,
        Heading: "Financial Results FY 2016",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_16.pdf",
        isActive: 1
    },
    {
        id: 35,
        Heading: "Financial Results FY 2016",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_16.pdf",
        isActive: 1
    },
    {
        id: 36,
        Heading: "Financial Results FY 2015",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_15.pdf",
        isActive: 1
    },
    {
        id: 37,
        Heading: "Financial Results FY 2015",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_15.pdf",
        isActive: 1
    },
    {
        id: 38,
        Heading: "Financial Results FY 2015",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_15.pdf",
        isActive: 1
    },
    {
        id: 39,
        Heading: "Financial Results FY 2015",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_15.pdf",
        isActive: 1
    },
    {
        id: 40,
        Heading: "Financial Results FY 2014",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_14.pdf",
        isActive: 1
    },
    {
        id: 41,
        Heading: "Financial Results FY 2014",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_14.pdf",
        isActive: 1
    },
    {
        id: 42,
        Heading: "Financial Results FY 2014",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_14.pdf",
        isActive: 1
    },
    {
        id: 43,
        Heading: "Financial Results FY 2014",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_14.pdf",
        isActive: 1
    },
    {
        id: 44,
        Heading: "Financial Results FY 2013",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_13.pdf",
        isActive: 1
    },
    {
        id: 45,
        Heading: "Financial Results FY 2013",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_13.pdf",
        isActive: 1
    },
    {
        id: 46,
        Heading: "Financial Results FY 2013",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_13.pdf",
        isActive: 1
    },
    {
        id: 47,
        Heading: "Financial Results FY 2013",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_12.pdf",
        isActive: 1
    },
    {
        id: 48,
        Heading: "Financial Results FY 2012",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_12.pdf",
        isActive: 1
    },
    {
        id: 49,
        Heading: "Financial Results FY 2012",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_12.pdf",
        isActive: 1
    },
    {
        id: 50,
        Heading: "Financial Results FY 2012",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_12.pdf",
        isActive: 1
    },
    {
        id: 51,
        Heading: "Financial Results FY 2012",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_12.pdf",
        isActive: 1
    },
    {
        id: 52,
        Heading: "Financial Results FY 2011",
        Heading2: "Quarterly Results",
        Title: "Quarter 1",
        PDF: "https://educomp.com/Data/Q1QR_11.pdf",
        isActive: 1
    },
    {
        id: 53,
        Heading: "Financial Results FY 2011",
        Heading2: "Quarterly Results",
        Title: "Quarter 2",
        PDF: "https://educomp.com/Data/Q2QR_11.pdf",
        isActive: 1
    },
    {
        id: 54,
        Heading: "Financial Results FY 2011",
        Heading2: "Quarterly Results",
        Title: "Quarter 3",
        PDF: "https://educomp.com/Data/Q3QR_11.pdf",
        isActive: 1
    },
    {
        id: 55,
        Heading: "Financial Results FY 2011",
        Heading2: "Quarterly Results",
        Title: "Quarter 4",
        PDF: "https://educomp.com/Data/Q4QR_11.pdf",
        isActive: 1
    }
];
